<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-30 18:04:41
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="phote" v-if="zhanshi" @click="zhanshi=false">
        <img :src="img">
    </div>
</template>

<script>
export default {
  name: 'phone',

  data () {
    return {
      img: '',
      zhanshi: false
    }
  },
  watch: {
    zhanshi () {
      if (!this.zhanshi) {
        this.img = ''
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    jichu (img) {
      this.zhanshi = true
      this.img = img
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.phote {
    position: fixed;
    left: 0;
    top:0;
    right:0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
    cursor: pointer;
    img{
        position: relative;
        top: 10%;
        height: 80%;
        max-width: 80%;
        display: block;
        margin: 0 auto;
    }
}
</style>
