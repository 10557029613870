<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="sqe">
        <ul class="sqe_tit">
            <li v-for="(i,index) in anniu_list" :key="i.id" @click="dianji_anniu(i,index)" :class="i.id==1?'zise':''">
                {{i.name}}
            </li>
            <li v-for="(i,index) in kanshei" :class="kanshei_num==i.id?'zise':''" @click="kanshei_num=i.id">
                {{i.name}}
            </li>
        </ul>
        <div class="gongying_shang_table">
            <el-table
                ref="gongying_shang_ref"
                :data="gongying_shang_list"
                :cell-style="liebiao"
                :header-cell-style="biaotou"
                tooltip-effect="dark"
                height="6.5rem"
                @row-click='hangdian'
                style="width: 100%;font-size: 14px"
                @selection-change="xuanzhong">
                <el-table-column
                    type="selection"
                    width="77">
                </el-table-column>
                <el-table-column v-for="(i,index) in biao1_tit" :key="index"
                    :prop="i.con"
                    :label="i.name"
                    min-width="150"
                    >
                    <template slot-scope="props">{{props.row[i.con]}}
                        <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">—</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
            @size-change="dianji_tiaoshu"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
            :page-size='tiaoshu'
            layout="total,sizes, prev, pager, next, jumper"
            :total="dangqian_zongshu">
        </el-pagination>
        <div class="beijing" @click='youce_xiangqing=false' v-if="youce_xiangqing">
            <div class="youce_box" @click.stop >
                <div class="youce_box_yanjing">
                    <div>
                        <p>
                            采购日期
                        </p>
                        <p>{{rqi}}</p>
                    </div>
                    <div>
                        <p>
                            成交金额
                        </p>
                        <p>{{jine}}</p>
                    </div>
                    <p v-if="xiangxi_xinxi[0].staff_id==$jichuxinxi().user_id" @click="dakai_chanpin">标签设置</p>
                    <p v-if="xiangxi_xinxi[0].staff_id==$jichuxinxi().user_id" @click="weihu_zhankai=!weihu_zhankai">报价单维护</p>
                    <!-- <img src="../../../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt=""> -->
                </div>
                <table class="youce_box_baojia_dan_weihu" v-if="weihu_zhankai">
                    <tr>
                        <td>1、下载报价单维护</td>
                        <td>
                            <img src="../../../assets/images/xiazai.png" alt="">
                        </td>
                        <td>
                            <p @click="dianji_xiazai_moban">下载</p>
                        </td>
                    </tr>
                    <tr>
                        <td>2、选取文件</td>
                        <td>
                            <img src="../../../assets/images/xuanze.png" alt="">
                        </td>
                        <td>
                            <!-- <p v-if="wenjian_liebiao.length==0">
                                <input type="file" @change="xuanze_wenjian">
                                选取文件
                            </p> -->
                            <el-upload
                                v-if="wenjian_liebiao.length==0"
                                class="upload-demo2"
                                ref="upload"
                                action=''
                                :file-list="wenjian_liebiao"
                                accept=".xls,.xlsx"
                                :multiple="false"
                                :auto-upload="false">
                                <el-button style="background: #9a86db;border:none" slot="trigger" size="small" type="primary">选取文件</el-button>
                            </el-upload>
                            <!-- <div v-if="wenjian_liebiao.length!=0">
                                <el-tooltip class="item" effect="dark" :content="wenjian_liebiao[0].name" placement="top-start">
                                    <span>{{wenjian_liebiao[0].name}}</span>
                                </el-tooltip>
                                <i class="el-icon-circle-close" @click="wenjian_liebiao.splice(0,1)"></i>
                            </div> -->
                        </td>
                    </tr>
                    <tr>
                        <td>3、上传报价单维护</td>
                        <td>
                            <img src="../../../assets/images/shangchuan.png" alt="">
                        </td>
                        <td>
                            <p @click='dianji_shangchuan_wenjian'>上传文件</p>
                        </td>
                    </tr>
                </table>
                <div class="youce_xiangxi_xinxi"  v-for='(item,idx) in xiangxi_xinxi' :key="idx">
                    <!-- {{idx+1}}、 -->
                    <p class="xiangxi_tit" style="background:#eee;" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_zhankai" style="background:#eee;" class="transition-box">
                            <p v-if="item.supply_name!==undefined">供应商名称：{{item.supply_name}}</p>
                            <p v-if="item.staff_name!==undefined">负责人：{{item.staff_name}}</p>
                            <p v-if="item.dept_name!==undefined">部门：{{item.dept_name}}</p>
                            <ul>
                                <li v-for='(i,index) in item.supply_detail' :key="index">
                                    <p>联系人({{index+1}})</p>
                                    <p v-if="i.name!==undefined">姓名：{{i.name}}</p>
                                    <p v-if="i.sex!==undefined">性别：{{i.sex}}</p>
                                    <p v-if="i.dept!==undefined">部门：{{i.dept}}</p>
                                    <p v-if="i.post!==undefined">职务：{{i.post}}</p>
                                    <p v-if="i.fixed_phone!==undefined">座机：{{i.fixed_phone}}</p>
                                    <p v-if="i.mobile!==undefined">手机：{{i.mobile}}</p>
                                    <p v-if="i.birthday!==undefined">生日：{{i.birthday}}</p>
                                    <p v-if="i.interest!==undefined">爱好：{{i.interest}}</p>
                                    <p v-if="i.qq!==undefined">QQ：{{i.qq}}</p>
                                    <p v-if="i.wechat!==undefined">微信：{{i.wechat}}</p>
                                    <p v-if="i.email!==undefined">邮箱：{{i.email}}</p>
                                    <p v-if="i.card!==undefined">名片：<img @click="tupian_dianji(i.card)" class="tupian_kuang" :src="i.card" alt=""></p>
                                </li>
                            </ul>
                            <p v-if="item.province!==undefined">省份：{{item.province}}</p>
                            <p v-if="item.city!==undefined">城市：{{item.city}}</p>
                            <p v-if="item.industry!==undefined">行业：{{item.industry}}</p>
                            <p v-if="item.source!==undefined">供应商来源：{{item.source}}</p>
                            <p v-if="item.level!==undefined">供应商等级：{{item.level}}</p>
                            <p v-if="item.tag_name!==undefined&&item.tag_name.length!=0">供应商标签：{{item.tag_name}}</p>
                            <p v-if="item.biz_addr!==undefined">经营地址：{{item.biz_addr}}</p>
                            <p v-if="item.rel_p_com!==undefined">母公司名称：{{item.rel_p_com}}</p>
                            <p v-if="item.register_addr!==undefined">注册地址：{{item.register_addr}}</p>
                            <p v-if="item.legal_name!==undefined">法定代表人：{{item.legal_name}}</p>
                            <p v-if="item.uscc!==undefined">纳税识别号：{{item.uscc}}</p>
                            <p v-if="item.register_capital!==undefined">注册资本：{{item.register_capital}}</p>
                            <p v-if="item.register_date!==undefined">成立日期：{{item.register_date}}</p>
                            <p v-if="item.biz_term!==undefined">营业期限：{{item.biz_term}}</p>
                            <p v-if="item.biz_scope!==undefined">营业范围：{{item.biz_scope}}</p>
                            <p v-if="item.biz_lic_pic!==undefined&&item.biz_lic_pic.length!=0">营业执照：<img @click="tupian_dianji(item.biz_lic_pic)" class="tupian_kuang" :src="item.biz_lic_pic" alt=""></p>
                            <p v-if="item.ent_nature!==undefined">企业性质：{{item.ent_nature}}</p>
                            <p v-if="item.bank_name!==undefined">开户行名称：{{item.bank_name}}</p>
                            <p v-if="item.bank_acct!==undefined">银行账号：{{item.bank_acct}}</p>
                            <p v-if="item.open_acct_pic!==undefined&&item.open_acct_pic.length!=0">开户许可证：<img @click="tupian_dianji(item.open_acct_pic)" class="tupian_kuang" :src="item.open_acct_pic" alt=""></p>
                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </transition>
                </div>
                <!-- 附件下载多了个a注意，以后该这块需要注意 -->
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxia">
                    <p class="xiangxi_tit" style="background:#eee;" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" style="background:#eee;" class="transition-box">
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[0].pur_annex!=undefined">
                                <p class="fujian_con_tit">采购合同:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[0].pur_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[0].pur_inv_annex!=undefined">
                                <p class="fujian_con_tit">采购发票:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[0].pur_inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[0].in_store_annex!=undefined">
                                <p class="fujian_con_tit">入库单:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[0].in_store_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[0].payment_annex!=undefined">
                                <p class="fujian_con_tit">付款单:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[0].payment_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[0].payment_annex!=undefined||fujian_list[0].pur_annex!=undefined||fujian_list[0].in_store_annex!=undefined||fujian_list[0].pur_inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[0].payment_annex==undefined&&fujian_list[0].pur_annex==undefined&&fujian_list[0].in_store_annex==undefined&&fujian_list[0].pur_inv_annex==undefined)">该供应商下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')">全部</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in zhanshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="chanpin_leixing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <el-select v-model="xuanze_chanpin_id" clearable @change="xuanze_chanpin_dalei" class='shiyong_biaodan_xiala_kuang' placeholder="请选择">
                        <el-option
                        v-for="item in qiye_chanpin_leixing_list"
                        :key="item.cat_id"
                        :label="item.cat_name"
                        :value="item.cat_id">
                        </el-option>
                    </el-select>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <!-- <ul>
                    <li v-for="i in yixuan_chanpin" :key="i.prod_id">{{i.name}}</li>
                </ul> -->
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for="(i,index) in yixuan_chanpin" :key="i.prod_id">
                        <i @click="shanchu_yixuan_chanpin(i,index)" class="el-icon-error"></i>
                        {{i.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- @current-change="chooseMcaterialChange" -->
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi"
                        :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tableb"
                        style="width: 100%"
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <tupian ref="bigphote"></tupian>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="zhipai_liebiao">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" @click="dianji_chaosong_xuanren(a)" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" :src="a.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}---{{a.dept_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="zhipai_liebiao=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query_ent_dept_list_of_all, query_dept_staff_list, change_supply_staff, query_form_replace_write, update_supply_prod_ids, query_ent_dept_list_aim, supply_annex_download, query_form_use_power_list, query_user_info, query_pur_date_and_pur_amt_sum, delete_supply, query_all_module_read_power_list, query_prod_info_list, get_prod_list_like, query_all_prod_list_of_category, query_category_info_list, query_appr_detail, query_flow_info_all, query_supply_detail, query_supply_mng_list } from '../../../api/api.js'
import tupian from '../../../components/bigphote/bigphote'
import axios from '../../../api/ajax'
export default {
  name: 'sqe',
  data () {
    return {
      anniu_list: [],
      kanshei: [],
      kanshei_num: '2',
      gongying_shang_list: [],
      xuan_zhong: '',
      biao1_tit: [
        {
          name: '供应商',
          con: 'supply_name'
        },
        {
          name: '供应商等级',
          con: 'level'
        },
        {
          name: '供应商标签',
          con: 'tag_name'
        },
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '负责人',
          con: 'staff_name'
        },
        {
          name: '联系人',
          con: 'rel_name'
        },
        {
          name: '供应商来源',
          con: 'source'
        },
        {
          name: '行业',
          con: 'industry'
        },
        {
          name: '省份',
          con: 'province'
        }
      ],
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,

      youce_xiangqing: false,
      fujian_list: [],
      xiangqing_zhankai: true, // 详细信息
      weihu_zhankai: false, // 详细信息
      xiangxi_xinxi: [],
      xiangqing_kehu_zhankai: false, // 供应商展开
      zhanshi_lishi: [], // 供应商操作日志列表
      xiangqing_kehu_fujian: false, // 附件展开
      fujian_list: [], // 附件list

      wenjian_liebiao: [], // 选择的文件
      xianshi_shezhi: false, // 显示设置
      zhanshi_list: [
        {
          name: '供应商',
          con: 'supply_name',
          zhi: false
        },
        {
          name: '供应商等级',
          con: 'level',
          zhi: false
        },
        {
          name: '供应商标签',
          con: 'tag_name',
          zhi: false
        },
        {
          name: '部门',
          con: 'dept_name',
          zhi: false
        },
        {
          name: '负责人',
          con: 'staff_name',
          zhi: false
        },
        {
          name: '联系人',
          con: 'rel_name',
          zhi: false
        },
        {
          name: '供应商来源',
          con: 'source',
          zhi: false
        },
        {
          name: '行业',
          con: 'industry',
          zhi: false
        },
        {
          name: '省份',
          con: 'province',
          zhi: false
        },
        {
          name: '城市',
          con: 'city',
          zhi: false
        },
        {
          name: '经营地址',
          con: 'biz_addr',
          zhi: false
        },
        {
          name: '母公司名称',
          con: 'rel_p_com',
          zhi: false
        },
        {
          name: '注册地址',
          con: 'register_addr',
          zhi: false
        },
        {
          name: '法定代表人',
          con: 'legal_name',
          zhi: false
        },
        {
          name: '营业执照号',
          con: 'uscc',
          zhi: false
        },
        {
          name: '注册资本',
          con: 'register_capital',
          zhi: false
        },
        {
          name: '成立日期',
          con: 'register_date',
          zhi: false
        },
        {
          name: '营业期限',
          con: 'biz_term',
          zhi: false
        },
        {
          name: '营业范围',
          con: 'biz_scope',
          zhi: false
        }
      ],
      xianshi_xiang_list: [],

      shifou_have_daixie: false, // 是否代写

      chanpin: false, // 标签设置   打开选择产品
      xuanze_chanpin_id: '',
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_liebiao_list: [], // 产品列表
      qiye_chanpin_leixing_list: [], // 企业大类列表
      chanpin_xuanze: [],

      quanbu_kejian: false, // 是否全部可见

      rqi: '', // 日期
      jine: '', // 金额
      fujian_quanxuan: false, // 附件全选
      zhuguan: false, // 主管
      dept_id: '',
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/'

      yixuan_chanpin: [], // 供应商标签已选产品
      tianxie_quanli: false, // 是否有编辑权限
      geren_shuxing: '',

      zhipai_liebiao: false, // 修改负责人列表是否展示
      chaosong_qiye_bumen_list: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_renyuan_list_xuan: [],
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      xitong: '',
      xianshi_quanbu: false,
      dangqian_xuanzhong_gys: ''
    }
  },
  mounted () {
    this.jichu2()
    var agent = navigator.userAgent.toLowerCase()
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if(agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
      this.xitong = 'win32'
    }
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      this.xitong = 'win64'
    }
    if(isMac){
      this.xitong = 'Mac'
    }
  },
  components: { tupian },
  created () {
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')

    sessionStorage.removeItem('mlbb_shenpi_biaodan')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_2')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_3')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_4')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_5')
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    this.chushi_chaxun()
    this.jichu()
  },
  watch: {
    dangqian_yeshu () {
      // sessionStorage.setItem('cbr_yifen_pei', JSON.stringify(this.dangqian_yeshu))
      this.gongying_shang_guanli_liebiao()
    },
    tiaoshu () {
      this.gongying_shang_guanli_liebiao()
    },
    kanshei_num () {
      this.gongying_shang_guanli_liebiao()
    },
    youce_xiangqing () {
      if (!this.youce_xiangqing) {
        this.wenjian_liebiao = []
        this.weihu_zhankai = false
        this.dangqian_xuanzhong_gys = ''
      }
    },
    xianshi_shezhi: {
      handler (newValue, oldValue) {
        if (this.xianshi_shezhi == false) {
          this.zhanshi_list.forEach(item => { item.zhi = false })
          this.xianshi_xiang_list = []
        }
      }
    },
    chanpin () {
      if (!this.chanpin) {
        this.xuanze_chanpin_id = ''
        this.sousuo_chanpin_text = ''// 搜索产品text
        this.chanpin_liebiao_list = []// 产品列表
        this.qiye_chanpin_leixing_list = []// 企业大类列表
        this.chanpin_xuanze = []
        this.yixuan_chanpin = []
      }
    },
    zhipai_liebiao () {
      if (!this.zhipai_liebiao) {
        this.chaosong_qiye_bumen_list = []
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    }
    // yixuan_chanpin(){
    //     this.no_xuan_chanpin(this.chanpin_liebiao_list)
    // }
  },
  methods: {
    jichu2 () {
      query_ent_dept_list_aim({
        data: {
          ent_id: this.$ent_id(),
          user_id: (this.quanbu_kejian || this.zhuguan) ? null : this.$jichuxinxi().user_id
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 10168) {
          const date = JSON.parse(ras.data.body.data)
          this.dept_id = (this.quanbu_kejian || this.zhuguan) ? null : date.map(item => item.dept_id).join(',')
          // this.bumen_tree=date
        } else if (ras.data.code == 10169) {}
      })
    },
    chushi_chaxun () { // 查询他有没有代写权限
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          if (date != undefined && date != null && date.length != 0) {
            date.forEach(item => {
              if (item.staff_id == _this.$jichuxinxi().user_id) {
                _this.shifou_have_daixie = true
              }
            })
          } else {
            this.shifou_have_daixie = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    jichu () {
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
          }
        } else if (res.data.code == 500) {}
      })
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const geren_shuxing = res.data.body
          this.geren_shuxing = JSON.parse(JSON.stringify(res.data.body))
          query_form_use_power_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id
            }
          }).then(ras => {
            console.log(ras)
            if (ras.data.code == 200) {
              const data = JSON.parse(ras.data.body.data)
              console.log(data)
              const zhan = []
              data.forEach(item => {
                item.children.forEach(it => {
                  if (it.flow_id == '5FotYgnu0Z') {
                    zhan.push('1111')
                  }
                })
              })
              const list = []
              if (zhan.length != 0) {
                list.push({
                  name: '新增',
                  id: '1'
                })
                list.push({
                  name: '编辑',
                  id: '2'
                })
              }
              list.push({
                name: '导入',
                id: '3'
              })
              if (geren_shuxing.staff_level == 1) {
                this.zhuguan = true
                list.push({
                  name: '编辑',
                  id: '2'
                })
                list.push({
                  name: '删除',
                  id: '4'
                })
              }
              list.push({
                name: '显示设置',
                id: '5'
              })
              const kanshei = [{
                name: '看自己',
                id: '2'
              }]
              if (geren_shuxing.staff_level == 1 || geren_shuxing.staff_level == 2 || geren_shuxing.dept_manager == 1) {
                list.push({
                  name: '变更负责人',
                  id: '6'
                })
                this.tianxie_quanli = true // 是否有编辑权限
              } else {
                this.tupian_dianji = false
              }
              if (this.quanbu_kejian || geren_shuxing.staff_level == 1 || geren_shuxing.staff_level == 2 || geren_shuxing.dept_manager == 1) {
                kanshei.push({
                  name: '看员工',
                  id: '1'
                })
                kanshei.push({
                  name: '看全部',
                  id: '3'
                })
              }
              this.anniu_list = this.$func.Es5duplicate(list, 'id')
              this.kanshei = kanshei
              this.gongying_shang_guanli_liebiao()
            } else if (ras.data.code == 500) {}
          })
        } else if (res.data.code == 500) {}
      })
    },
    gongying_shang_guanli_liebiao () {
      query_supply_mng_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          look_type: this.kanshei_num + '',
          search_str: null,
          page_size: this.tiaoshu + '',
          page_index: this.dangqian_yeshu + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.gongying_shang_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.gongying_shang_list = []
          }
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;font-size:0.14rem;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    hangdian (val) {
      console.log(val)
      this.xiangxi_xinxi = []
      query_supply_detail({
        data: {
          ent_id: this.$ent_id(),
          supply_id: val.supply_id,
          flow_no: val.flow_no
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.xiangxi_xinxi.push(date)
          this.yixuan_chanpin = date.prod_json != undefined ? JSON.parse(date.prod_json) : []
          this.dangqian_xuanzhong_gys = date
          console.log(date)

          this.youce_xiangqing = true
          // 附件下载接口
          supply_annex_download({
            data: {
              ent_id: this.$ent_id(),
              supply_id: val.supply_id != undefined ? val.supply_id : null
            }
          }).then(fujian => {
            console.log(fujian)
            if (fujian.data.code == 200) {
              let date = JSON.parse(fujian.data.body.data)
              console.log(date, 1111)

              if (date.length != 0) {
                date.forEach(item => {
                  if (item.pur_annex != undefined) {
                    item.pur_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                  if (item.pur_inv_annex != undefined) {
                    item.pur_inv_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                  if (item.in_store_annex != undefined) {
                    item.in_store_annex.forEach(ite => {
                      this.$set(ite, 'zhi', false)
                    })
                  }
                })
              } else {
                date = []
              }
              // for(let i=0;i<date.length;i++){
              //     if(date[i].file_no==undefined){
              //         date.splice(i,1)
              //         i=i-1
              //     }
              // }
              setTimeout(() => {
                this.fujian_list = date
                console.log(this.fujian_list)
              }, 0)
            } else if (fujian.data.code == 500) {}
          })
        } else if (res.data.code == 500) {
          alert('系统报错')
        }
      })
      query_pur_date_and_pur_amt_sum({
        data: {
          ent_id: this.$ent_id(),
          supply_id: val.supply_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != null && date != undefined) {
            this.rqi = date.date != undefined && date.date.length != 0 ? date.date : '-'
            this.jine = date.amt_sum
          } else {
            this.rqi = '-'
            this.jine = '-'
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 图片放大
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    },
    xuanzhong (val) {
      this.xuan_zhong = val
      console.log(val)
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    xuanze_wenjian () {
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      const lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
      // 转base64
      const arr = []
      var _this = this
      reader.onload = function (e) {
        arr.push(e.target.result)
        const obj = {
          name: file.name,
          b64: e.target.result.substring(e.target.result.indexOf(',') + 1, e.target.result.length),
          type: lei
        }
        _this.wenjian_liebiao.push(obj)
      }
      reader.readAsDataURL(file)
    },
    dianji_anniu (i, index) {
      console.log(i)
      console.log(index)
      if (i.name == '显示设置') {
        this.biao1_tit.forEach(item => {
          this.zhanshi_list.forEach(ite => {
            if (item.con == ite.con) {
              this.xuanze_danxuan(ite)
            }
          })
        })
        this.xianshi_shezhi = true
      } else if (i.name == '新增') {
        query_flow_info_all({
          data: {
            ent_id: this.$ent_id(),
            flow_id: '5FotYgnu0Z'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = res.data.body.form_str
            console.log(date)
            const data = []
            for (let a = 0; a < date.length; a++) {
              if (date[a].defaultImportant != undefined) {
                date[a].defaultImportant = date[a].defaultImportant != 'false'
              }
              if (date[a].defaultPrint != undefined) {
                date[a].defaultPrint = date[a].defaultPrint != 'false'
              }
              if (date[a].defaultOptions != undefined) {
                date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
              }
              if (date[a].gongshi != undefined) {
                date[a].gongshi = JSON.parse(date[a].gongshi)
              }
              if (date[a].supportSetting != undefined) {
                date[a].supportSetting = JSON.parse(date[a].supportSetting)
              }
              if (date[a].components != undefined) {
                date[a].components = JSON.parse(date[a].components)
                for (let b = 0; b < date[a].components.length; b++) {
                  if (date[a].components[b].defaultImportant != undefined) {
                    if (typeof (date[a].components[b].defaultImportant) === 'string') {
                      date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                    }
                  }
                  if (date[a].components[b].defaultPrint != undefined) {
                    if (typeof (date[a].components[b].defaultPrint) === 'string') {
                      date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                    }
                  }
                  // typeof(str)=='string'
                  if (date[a].components[b].defaultOptions != undefined) {
                    if (typeof (date[a].components[b].defaultOptions) === 'string') {
                      date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                    }
                  }
                  if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                    if (typeof (date[a].components[b].gongshi) === 'string') {
                      console.log(date[a].components[b].gongshi)
                      date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                    }
                  }
                  if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                    if (typeof (date[a].components[b].supportSetting) === 'string') {
                      console.log(date[a].components[b].supportSetting)
                      date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                    }
                  }
                }
              }
              data.push(date[a])
            }
            const obj = {
              flow_id: '5FotYgnu0Z',
              flow_name: '供应商录入单'
            }
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
            this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '已分配', '暂时')
            this.$router.push('/usetable')
          } else if (res.data.code == 500) {}
        })
      } else if (i.name == '编辑') {
        if (this.xuan_zhong.length == 1) {
          console.log(this.xuan_zhong[0])
          // ||this.tianxie_quanli
          if ((this.xuan_zhong[0].staff_id == this.$jichuxinxi().user_id) || this.shifou_have_daixie) {
            query_supply_detail({
              data: {
                ent_id: this.$ent_id(),
                supply_id: this.xuan_zhong[0].supply_id,
                flow_no: this.xuan_zhong[0].flow_no
              }
            }).then(rds => {
              console.log(rds)
              if (rds.data.code == 200) {
                const xiangqing = JSON.parse(rds.data.body.data)
                console.log(xiangqing)
                if (xiangqing.flow_status == undefined || xiangqing.flow_status == 4 || xiangqing.flow_status == 2 || xiangqing.flow_status == 7) {
                  query_flow_info_all({
                    data: {
                      ent_id: this.$ent_id(),
                      flow_id: '5FotYgnu0Z'
                    }
                  }).then(res => {
                    console.log(res)
                    if (res.data.code == 200) {
                      const date = res.data.body.form_str
                      console.log(date)
                      const data = []
                      for (let a = 0; a < date.length; a++) {
                        if (date[a].defaultImportant != undefined) {
                          date[a].defaultImportant = date[a].defaultImportant != 'false'
                        }
                        if (date[a].defaultPrint != undefined) {
                          date[a].defaultPrint = date[a].defaultPrint != 'false'
                        }
                        if (date[a].defaultOptions != undefined) {
                          date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
                        }
                        if (date[a].gongshi != undefined) {
                          date[a].gongshi = JSON.parse(date[a].gongshi)
                        }
                        if (date[a].supportSetting != undefined) {
                          date[a].supportSetting = JSON.parse(date[a].supportSetting)
                        }
                        if (date[a].components != undefined) {
                          date[a].components = JSON.parse(date[a].components)
                          for (let b = 0; b < date[a].components.length; b++) {
                            if (date[a].components[b].defaultImportant != undefined) {
                              if (typeof (date[a].components[b].defaultImportant) === 'string') {
                                date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                              }
                            }
                            if (date[a].components[b].defaultPrint != undefined) {
                              if (typeof (date[a].components[b].defaultPrint) === 'string') {
                                date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                              }
                            }
                            // typeof(str)=='string'
                            if (date[a].components[b].defaultOptions != undefined) {
                              if (typeof (date[a].components[b].defaultOptions) === 'string') {
                                date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                              }
                            }
                            if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                              if (typeof (date[a].components[b].gongshi) === 'string') {
                                console.log(date[a].components[b].gongshi)
                                date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                              }
                            }
                            if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                              if (typeof (date[a].components[b].supportSetting) === 'string') {
                                console.log(date[a].components[b].supportSetting)
                                date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                              }
                            }
                          }
                        }
                        data.push(date[a])
                      }
                      const obj = {
                        flow_id: '5FotYgnu0Z',
                        flow_name: '供应商录入单'
                      }
                      data.forEach(item => {
                        if (item.componentName == 'suppliername' && item.defaultLable == '供应商名称' && item.idx == '1599916753705') {
                          this.$set(item, 'value_id', this.xuan_zhong[0].supply_id)
                          this.$set(item, 'value', this.xuan_zhong[0].supply_name)
                        }
                        if (item.componentName == 'department' && item.defaultLable == '部门' && item.idx == '1599916769379') {
                          this.$set(item, 'option', [{
                            dept_id: this.xuan_zhong[0].dept_id,
                            dept_name: this.xuan_zhong[0].dept_name
                          }])
                          this.$set(item, 'dept_id', this.xuan_zhong[0].dept_id)
                          this.$set(item, 'value', this.xuan_zhong[0].dept_name)
                          this.$set(item, 'dept_idid', this.xuan_zhong[0].dept_id.split(','))
                        }
                        if (item.componentName == 'province' && item.defaultLable == '省市区' && item.idx == '1599916987721') {
                          this.$set(item, 'value', xiangqing.province)
                          this.$set(item, 'value_id', xiangqing.city)
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '行业' && item.idx == '1599916993690') {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.industry) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '供应商来源' && item.idx == '1599917155770') {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.source) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddselectfield' && item.defaultLable == '供应商等级' && item.idx == '1599917213978') {
                          console.log(item)
                          item.defaultOptions.forEach((ite, ind) => {
                            if (ite.text == xiangqing.level) {
                              this.$set(item, 'value', ind)
                              this.$set(item, 'teshu', false)
                            }
                          })
                          if (item.value == undefined) {
                            this.$set(item, 'value', '0')
                            this.$set(item, 'teshu', false)
                          }
                        }
                        if (item.componentName == 'ddmultiselectfield' && item.defaultLable == '供应商标签' && item.idx == '1599917545442') {
                          this.$set(item, 'teshu', false)
                          this.$set(item, 'value', xiangqing.tag_name)
                          this.$set(item, 'value_id', xiangqing.tag)
                        }
                        if (item.componentName == 'textfield' && item.defaultLable == '经营地址' && item.idx == '1599917571114') {
                          this.$set(item, 'value', xiangqing.biz_addr != undefined ? xiangqing.biz_addr : '')
                          this.$set(item, 'value_id', 0)
                        }
                        if (item.componentName == 'textfield' && item.defaultLable == '母公司名称' && item.idx == '1599917588963') {
                          this.$set(item, 'value', xiangqing.rel_p_com != undefined ? xiangqing.rel_p_com : '')
                          this.$set(item, 'value_id', 0)
                        }
                      })
                      console.log(data)
                      console.log(xiangqing)

                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '公海池_编辑', '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', xiangqing, '暂时')
                      this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_5', {
                        shuxing: this.geren_shuxing,
                        fuze_ren: this.$jichuxinxi().user_id == this.xuan_zhong[0].user_id
                      }, '暂时')
                      this.$router.push('/usetable')
                    } else if (res.data.code == 500) {}
                  })
                } else {
                  this.$message({
                    message: '当前供应商尚未审批完成',
                    type: 'warning'
                  })
                }
              } else if (rds.data.code == 500) {}
            })
          } else {
            this.$message({
              message: '您不能编辑不属于自己的供应商',
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '请选择单个供应商进行编辑',
            type: 'warning'
          })
        }
      } else if (i.name == '删除') {
        if (this.xuan_zhong.length != 0) {
          delete_supply({
            data: {
              ent_id: this.$ent_id(),
              supply_ids: this.xuan_zhong.map(item => item.supply_id).join(',')
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.gongying_shang_guanli_liebiao()
            } else if (res.data.code == 500) {} else if (res.data.code == 11600) {
              this.$message({
                message: '该供应商不可删除',
                type: 'warning'
              })
            }
          })
        }
      } else if (i.name == '导入') {
        this.$router.push('/importextgong')
      } else if (i.id == 6) {
        if (this.xuan_zhong.length == 1) {
          this.zhipai_liebiao = true
          this.bumen_jiekou('0')
        } else {
          this.$message({
            message: '请选择单个供应商进行修改负责人',
            type: 'warning'
          })
        }
      }
    },
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.zhanshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.zhanshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.zhanshi_list[i])
        }
      } else {
        this.zhanshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.zhanshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.biao1_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    // 点击下载模板
    dianji_xiazai_moban () {
      // let data=['https://www.mlcbr.com/template/CBR-供应商产品报价单导入模板.xlsx']
      // this.$func.multiDownLoad(data)
      const date = this.url + 'export_supply_quotation_template?ent_id=' + this.$ent_id() + '&supply_id=' + this.xiangxi_xinxi[0].supply_id
      this.$func.multiDownLoad([date])
    },
    dianji_shangchuan () {
      axios({
        method: 'post',
        url: '/import_quotation_ai',
        type: 'post',
        data: form,
        onUploadProgress: progressEvent => {
          var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
          // this.text = complete + '%'
          console.log(complete + '%')
          // this.$refs.jindu_tiao.huoqu(true,complete)
        }
      }).then(res => {
        console.log(res)
        // this.$refs.jindu_tiao.huoqu(false,0)
        sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
        sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '报价单维护')
        if (res.data.code == 10175) {
          this.$message({
            message: '导入成功',
            type: 'success'
          })
          this.$router.push('/feedbackpage')
        } else if (res.data.code == 10176) {
          this.$message({
            message: '导入失败，请重新导入',
            type: 'error'
          })
        }
      })
    },
    xuanze_chanpin_dalei () {
      if (this.xuanze_chanpin_id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.xuanze_chanpin_id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.xuanze_chanpin_id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.xuanze_chanpin_id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    // 打开产品
    dakai_chanpin () {
      this.chanpin = true
      query_category_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: '0',
          active: '1'
        }
      }).then(res => {
        console.log(4444444, res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_list = date
        } else if (res.data.code == 10194) {
        }
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
    },
    no_xuan_chanpin (date) {
      var a = date
      this.chanpin_liebiao_list = a
      console.log(this.xiangxi_xinxi)
      // 查看当前的大类
      const cat_ids = this.xuanze_chanpin_id
      let cat_names = ''
      this.qiye_chanpin_leixing_list.forEach(item => {
        if (item.cat_id == this.xuanze_chanpin_id) {
          cat_names = item.cat_name
        }
      })
      // if(this.xiangxi_xinxi[0].prod_ids!=undefined&&this.xiangxi_xinxi[0].prod_ids.length!=0){
      //     this.chanpin_liebiao_list.forEach(item=>{
      //         this.xiangxi_xinxi[0].prod_ids.split(',').forEach(ite=>{
      //             if(ite==item.prod_id){
      //                 // console.log(item)
      //                 // console.log(this.$refs.Tableb)
      //                 // this.$refs.Tableb.toggleRowSelection(item)
      //                 this.$set(item,'cat_ids',cat_ids)
      //                 this.$set(item,'cat_names',cat_names)
      //                 this.yixuan_chanpin.push(item)  //把本地存的那些产品标签拿出来
      //             }
      //         })
      //     })
      //     console.log(1111)
      // }
      this.$nextTick(function () {
        this.yixuan_chanpin = this.dangqian_xuanzhong_gys.prod_json != undefined ? JSON.parse(this.dangqian_xuanzhong_gys.prod_json) : []
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
        this.yixuan_chanpin.forEach(item => {
          this.chanpin_liebiao_list.forEach(ite => {
            if (ite.prod_id == item.prod_id) {
              this.$refs.Tableb.toggleRowSelection(ite)
            }
          })
        })
      })
      console.log(2222)
      this.$forceUpdate()
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.yixuan_chanpin) // 这是所选的产品
      if (this.yixuan_chanpin.length != 0) {
        const shaixuan_dalei = this.$func.Es5duplicate(this.yixuan_chanpin, 'cat_ids')
        update_supply_prod_ids({
          data: {
            ent_id: this.$ent_id(),
            supply_id: this.xiangxi_xinxi[0].supply_id, // 供应商id
            prod_ids: this.yixuan_chanpin.map(item => item.prod_id).join(','),
            cat_id: shaixuan_dalei.map(item => item.cat_ids).join(','),
            cat_name: shaixuan_dalei.map(item => item.cat_name).join(','),
            user_id: this.$jichuxinxi().user_id,
            supply_name: this.xiangxi_xinxi[0].supply_name,
            prod_json: JSON.stringify(this.yixuan_chanpin)// 这个传所选的产品
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$set(this.xiangxi_xinxi[0], 'prod_ids', this.yixuan_chanpin.map(item => item.prod_id).join(','))
            this.$set(this.xiangxi_xinxi[0], 'tag', shaixuan_dalei.map(item => item.cat_ids).join(','))
            this.$set(this.xiangxi_xinxi[0], 'tag_name', shaixuan_dalei.map(item => item.cat_name).join(','))
            this.$set(this.xiangxi_xinxi[0], 'prod_json', JSON.stringify(this.yixuan_chanpin))
            this.gongying_shang_guanli_liebiao()
            this.chanpin = false
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请选择产品进行下步操作',
          type: 'warning'
        })
      }
    },
    dianji_quanxuan_fujian () {
      this.fujian_quanxuan = !this.fujian_quanxuan
      if (this.fujian_quanxuan) {
        const item = this.fujian_list[0]
        if (item != undefined) {
          if (item.pur_annex != undefined) {
            item.pur_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.pur_inv_annex != undefined) {
            item.pur_inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.in_store_annex != undefined) {
            item.in_store_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
          if (item.payment_annex != undefined) {
            item.payment_annex.forEach(ite => {
              this.$set(ite, 'zhi', true)
            })
          }
        }
      } else {
        const item = this.fujian_list[0]
        if (item != undefined) {
          if (item.pur_annex != undefined) {
            item.pur_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.pur_inv_annex != undefined) {
            item.pur_inv_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.in_store_annex != undefined) {
            item.in_store_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
          if (item.payment_annex != undefined) {
            item.payment_annex.forEach(ite => {
              this.$set(ite, 'zhi', false)
            })
          }
        }
      }
    },
    dianji_piliang_xiazi () {
      const list = []
      const item = this.fujian_list[0]
      if (item != undefined) {
        if (item.pur_annex != undefined) {
          item.pur_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.pur_inv_annex != undefined) {
          item.pur_inv_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.in_store_annex != undefined) {
          item.in_store_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (item.payment_annex != undefined) {
          item.payment_annex.forEach(ite => {
            if (ite.zhi) {
              list.push(ite)
            }
          })
        }
        if (list.length != 0) {
          list.forEach(it => {
            console.log(it,'sssssssssssssss')
            this.$func.fujian_xiazai2(it)
          })
        } else {
          this.$message({
            message: '请选择附件进行下载',
            type: 'warning'
          })
        }
        console.log(list)
      }
    },
    // 上传文件
    dianji_shangchuan_wenjian () {
      console.log(this.$refs.upload.uploadFiles)
      const date = this.$refs.upload.uploadFiles
      const list = []
      const shibai = []
      const fasong = []
      for (let i = 0; i < date.length; i++) {
        list.push(date[i].raw)
      }
      console.log(list)
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
          shibai.push(list[i])
        } else {
          const fileObj = list[i]
          const form = new FormData()// FormData 对象
          form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
          form.append('ent_id', this.$ent_id())
          sessionStorage.setItem('pc_mlbb_shangchuan_chenggong', fileObj.name)
          axios({
            method: 'post',
            url: '/import_quotation_ai',
            data: form,
            processData: false,
            contentType: false,
            timeout: 1200000,
            onUploadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              console.log(complete + '%')
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '供应商产品报价')
              this.$router.push('/feedbackpage')
            } else if (res.data.code == 500) {
              this.$message({
                message: '导入失败，请重新导入',
                type: 'error'
              })
            } else if (res.data.code == 11800) {
              this.$message({
                message: '导入文件的sheet页名不对',
                type: 'error'
              })
            } else if (res.data.code == 11900) {
              this.$message({
                message: '导入文件的模板格式不对',
                type: 'error'
              })
            }
          })
        }
      }
    },
    xuanze_chanpin (rows, row) {

      console.log(rows)// 所有选中的人
      console.log(row)// 当前选中的人
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      console.log(ses)
      if (ses) {
        this.qiye_chanpin_leixing_list.forEach(item => {
          if (item.cat_id == this.xuanze_chanpin_id) {
            this.$set(row, 'cat_names', item.cat_name)
          }
        })
        this.$set(row, 'cat_ids', this.xuanze_chanpin_id)
        this.yixuan_chanpin.push(row)
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
      } else {
        for (let i = 0; i < this.yixuan_chanpin.length; i++) {
          if (this.yixuan_chanpin[i].prod_id == row.prod_id) {
            this.yixuan_chanpin.splice(i, 1)
            break
          }
        }
      }
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      const cat_ids = this.xuanze_chanpin_id
      let cat_names = ''
      this.qiye_chanpin_leixing_list.forEach(item => {
        if (item.cat_id == this.xuanze_chanpin_id) {
          cat_names = item.cat_name
        }
      })
      if (rows.length != 0) {
        rows.forEach(item => {
          this.$set(item, 'cat_ids', cat_ids)
          this.$set(item, 'cat_names', cat_names)
          this.yixuan_chanpin.push(item)
        })
        this.yixuan_chanpin = this.$func.Es5duplicate(this.yixuan_chanpin, 'prod_id')
      } else {
        for (let i = 0; i < this.yixuan_chanpin.length; i++) {
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.yixuan_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.yixuan_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
        }
      }
    },
    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tableb.toggleRowSelection(ite)
        }
      })
      this.yixuan_chanpin.splice(index, 1)
    },

    // 增加修改负责人
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.bumen_jiekou(i.dept_id)
      }
    },
    bumen_jiekou (id) {
      query_ent_dept_list_of_all({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          parent_id: id,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          this.renyuan_jiekou(id)
        } else if (res.data.code == 10169) {}
      })
    },
    renyuan_jiekou (id) {
      console.log(id)

      query_dept_staff_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          dept_id: id,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10149) {
          const date = JSON.parse(res.data.body.data)
          date.forEach(item => {
            this.$set(item, 'name', item.staff_name)
            this.$set(item, 'zhi', false)
          })
          this.chaosong_qiye_renyuan_list = date
          console.log(date)
          this.jiancha_shifou_xuanzhong()
        } else if (res.data.code == 10150) {}
      })
    },
    // 检查是否选中
    jiancha_shifou_xuanzhong () {
      if (this.chaosong_renyuan_list_xuan.length != 0) {
        this.chaosong_qiye_renyuan_list.forEach(item => {
          if (item.staff_id == this.chaosong_renyuan_list_xuan[0].staff_id && item.dept_id == this.chaosong_renyuan_list_xuan[0].dept_id) {
            this.$set(item, 'zhi', true)
          }
        })
      }
    },
    // 点击部门
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      console.log(i)

      this.bumen_jiekou(i.dept_id)
    },
    dianji_chaosong_xuanren (i) {
      if (i.zhi) {
        i.zhi = false
        this.chaosong_renyuan_list_xuan = []
      } else {
        if (this.chaosong_renyuan_list_xuan.length != 0) {
          this.$message({
            message: '只能选择一个负责人',
            type: 'warning'
          })
        } else {
          i.zhi = true
          const obj = {
            name: i.name,
            staff_id: i.staff_id,
            dept_name: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].name,
            dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
          }
          this.chaosong_renyuan_list_xuan.push(obj)
        }
      }
    },
    // 修改负责人点击确定
    chaosong_renyuan_sure () {
      if (this.chaosong_renyuan_list_xuan.length != 0) {
        change_supply_staff({
          data: {
            ent_id: this.$ent_id(),
            supply_id: this.xuan_zhong[0].supply_id,
            staff_id: this.chaosong_renyuan_list_xuan[0].staff_id,
            dept_id: this.chaosong_renyuan_list_xuan[0].dept_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.gongying_shang_guanli_liebiao()
            this.zhipai_liebiao = false
          } else if (res.data.code == 500) {
            this.$message({
              message: '修改失败',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '请选择负责人进行修改',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../chanpin.scss';
 @import '../../../style/salesman.scss';
 .beijing{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index:100;
    background: rgba(0,0,0,0.5);
    .chanpin_leixing_box{
        height:6.8rem;
        width:10rem;
        background:#fff;
        position: relative;
        top:1.42rem;
        left:50%;
        margin-left: -5rem;
        border-radius: 0.04rem;
        margin-bottom: 1.4rem;
        .guanbi_xuanze_chanpin{
            position: absolute;
            top:-0.2rem;
            right:-0.2rem;
            font-size: 0.45rem;
            color:rgb(252, 106, 106);
            cursor: pointer;
        }
        .mingxi_chanpin_yangshi{
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            flex-wrap: wrap;
            li{
                padding: 0.06rem 0.12rem;
                border:1px dashed  #c4c7cd;
                font-size: 0.12rem;
                color:#c4c7cd;
                position: relative;
                margin-right: 0.08rem;
                margin-bottom: 0.08rem;
                cursor: pointer;
                i{
                    font-size: 0.14rem;
                    color:#c4c7cd;
                    position: absolute;
                    top:-0.07rem;
                    right:-0.07rem;
                    display: none;
                }
                &:hover{
                    color:#9a86db;
                    border:1px dashed #9a86db;
                    i{
                        color:#9a86db;
                        display: block;
                    }
                }
            }
        }
        .beijing_box_tit{
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            padding: 0.2rem;
            .shiyong_chanpin_dalei_kuang{
                position: relative;
                p{
                    height: 0.44rem;
                    width:1.4rem;
                    border:0.01rem solid #979797;
                    color:#1a2533;
                    font-size: 0.14rem;
                    padding:0 0.15rem;
                    cursor: pointer;
                    border-radius: 0.08rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin:0 0.13rem;
                    line-height: 0.44rem;
                    // display: flex;
                    // align-items: center;
                    // span{
                    //     padding: 0.05rem 0.13rem;
                    //     border:1px solid #ccc;
                    //     font-size: 0.12rem;
                    //     color: #1a2533;
                    //     cursor: pointer;
                    //     border-radius: 0.03rem;
                    //     margin-right: 0.05rem;
                    //     height:0.2rem;
                    //     line-height: 0.2rem;
                    // }
                }
                .rolekanban_box_tit_right_box_con{
                    position: absolute;
                    width: 2.09rem;
                    height:2.84rem;
                    border:1px solid #ededed;
                    padding: 0.15rem 0.09rem;
                    background-color: #fff;
                    z-index:100;
                }
            }
            .shiyong_sousuo_chanpin{
                position: relative;
                input{
                    width:1.12rem;
                    height:0.42rem;
                    border:0.01rem solid #979797;
                    border-radius: 0.04rem;
                    padding: 0 0.4rem;
                }
                i{
                    font-size:0.16rem;
                    position: absolute;
                }
                .el-icon-search{
                    top:0.15rem;
                    left:0.15rem;
                }
                .el-icon-circle-close{
                    top:0.15rem;
                    right:0.15rem;
                    display: none;
                    cursor: pointer;
                }
                &:hover{
                    .el-icon-circle-close{
                        display: block;
                    }
                }
                margin-right:0.1rem;
            }
            .shiyong_chanpin_queding{
                padding: 0.05rem 0.13rem;
                background: #9b84dc;
                font-size: 0.12rem;
                color:#fff;
                cursor: pointer;
                border-radius: 0.03rem;
            }
        }
    }
}
</style>
